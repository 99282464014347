@import '~normalize.css/normalize.css';

.app {
	font-family: sans-serif;
	margin: 0.5em;
}

header {
	@media only screen and (min-width: 960px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

h1 {
	margin: 0 0 .5em;
}

table {
	width: 100%;
	border-spacing: 0;
}

th,
td {
	text-align: center;
	padding: 1em 0;
}

thead th {
	background-color: black;
	color: white;
	padding: 1.75em 0;
}

tr:nth-child(2n) {
	background-color: #EEE;
}

@media only screen and (max-width: 480px) {
	thead {
		display: none;
	}
	tr {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding: 0 .5em;
	}
	th, td {
		display: block;
		text-align: left;
	}
	th {
		width: 100%;
		font-size: 1.5em;
		padding: .667em 0;
		margin: 0;
		&:before {
			display: inline;
			content: '#';
		}
	}

	td {
		padding-top: .25em;
		width: calc(33.33% - 1em);
		&:before {
			display: block;
			font-weight: bold;
			content: attr(data-label);
		}
	}
}
